import { useState } from "react";
import styled from "styled-components";
import { InternalSelect } from "./InternalSelect";
import { useHistory } from "react-router-dom";

type AdminControlsProps = {
  views: string[];
  selectedView: string;
  setAdminView: (view: string) => void;
};

export const AdminControls = ({
  views,
  selectedView,
  setAdminView,
}: AdminControlsProps) => {
  const [hidden, setHidden] = useState(false);
  const history = useHistory();

  const selectOptions = views.map((view) => ({
    label: `${view[0].toUpperCase()}${view.slice(1)}`,
    value: view,
  }));
  return (
    <ControlsContainer>
      <CollapseButton onClick={() => setHidden((v) => !v)}>
        {hidden ? "Show Navigation" : "Hide Navigation"}
      </CollapseButton>
      <SelectWrapper
        style={{ ...(hidden && { height: 0, overflow: "hidden" }) }}
      >
        <InternalSelect
          options={selectOptions}
          value={selectedView}
          onChange={(v) => {
            setAdminView(v);
            history.push(`?view=${v}`);
          }}
        />
      </SelectWrapper>
    </ControlsContainer>
  );
};

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 160px;
`;

const ControlsContainer = styled.div`
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const CollapseButton = styled.p`
  font-size: 10px;
  color: #fff;
  min-width: unset;
  padding: 4px;
  align-self: flex-end;
  cursor: pointer;
`;
