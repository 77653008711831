import styled from "styled-components";
import shareImage from "assets/marines-share.jpg";
import { questions } from "Routes/Root/Register/questions";
// @ts-ignore
import { ShareSocial } from "react-share-social";

const style = {
  background: "white",
  borderRadius: 3,
  border: 0,
  color: "white",
  padding: "0 0px",
  margin: "0 auto",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textDecoration: "none",
};

type Responses = {
  q1: string;
  q2: string;
  q3: string;
  q4: string;
};

type ShareCardProps = {
  url?: string;
  responses: Responses;
};

const MAPPINGS = {
  q1: {
    a: "Loyalty",
    b: "Honesty",
    c: "Dependability",
    d: "Unselfishness",
  },
  q2: {
    a: "Endurance",
    b: "Courage",
    c: "Initiative",
    d: "Decisiveness",
  },
  q3: {
    a: "Justice",
    b: "Judgement",
    c: "Knowledge",
    d: "Tact",
  },
};
type Traits = {
  q1?: string;
  q2?: string;
  q3?: string;
};
const ShareCard = ({ url, responses }: ShareCardProps) => {
  const getTraits = () => {
    const traits: Traits = {};
    for (const [qId, val] of Object.entries(responses)) {
      if (qId in MAPPINGS) {
        const questionMap = MAPPINGS[qId as keyof typeof MAPPINGS];
        const answerLabel = questionMap[val as keyof typeof questionMap];
        if (answerLabel) {
          traits[qId as keyof Traits] = answerLabel;
        }
      }
    }
    return traits;
  };

  const traits = getTraits();

  return (
    <ShareWrapper>
      <img src={shareImage} alt="marines line up parade" />
      {!!(traits && Object.keys(traits).length > 2) && (
        <h4>
          The traits I share with the Marines are {traits.q1}, {traits.q2}, and{" "}
          {traits.q3}.<br />
          What are yours?
          <br />
          <span>https://USMCMusic.com/shaylen</span>
        </h4>
      )}
      <ShareSocial
        style={style}
        url={url ? url : "https://usmcmusic.com/shaylen"}
        socialTypes={["facebook"]}
      />
    </ShareWrapper>
  );
};
export default ShareCard;

const ShareWrapper = styled.div`
  color: black;
  background-color: white;
  width: 100%;

  h4 {
    margin-top: 12px;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    span {
      color: #aeaeae;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 0;
  }
  .makeStyles-copyContainer-5 {
    display: none;
  }
`;
