import { DateMask } from "Components/Form/InputMasks/DateMask";
import { FieldMessage } from "Components/Form/Fields";
import { useState } from "react";

export const DateField = ({
  onChange,
  field,
  value,
  error = {},
  ageGate,
  ...props
}) => {
  const [inputValue, setInputValue] = useState();
  const handleText = (value) => {
    if (!!error.message) {
      value = "";
    }
    if (value) {
      let splitDate = value.split("/");
      if (
        splitDate[0]?.length !== 2 ||
        splitDate[1]?.length !== 2 ||
        splitDate[2]?.length !== 4 ||
        !splitDate[0]?.length ||
        !splitDate[1]?.length ||
        !splitDate[2]?.length
      ) {
        let newDate = value.replaceAll("/", "");
        if (newDate.length === 8) {
          let updatedValue = `${newDate[0]}${newDate[1]}/${newDate[2]}${newDate[3]}/${newDate[4]}${newDate[5]}${newDate[6]}${newDate[7]}`;
          value = updatedValue;
        }
      }
    }
    onChange({ [field.name]: value });
  };

  return (
    <>
      <DateMask
        date={value}
        value={inputValue}
        //   onFocus={()=>{!!error.message && onChange({ [field.name]: "" })}}
        onChange={(value) => handleText(value)}
        error={!!error.status}
        placeholder={field.placeholder ?? "DOB (mm/dd/yyyy)*"}
        ageGate={ageGate}
        {...props}
      />
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};
