import { ArtistContainerMobile, Layout, MainContainer } from "Layout";
import { Divider, Title } from "./Register";
import { ResponsiveBody, ResponsiveH1, Button } from "Components";
import styled from "styled-components";
import { useIsMobile } from "hooks/useMediaQuery";
import bannerDesktop from "assets/post_campaign-banner-desktop.jpg";
import bannerMobile from "assets/post_campaign-banner-mobile.jpg";
import { useAnalytics, useEvent } from "context";

const learnMoreUrl =
  "https://rmi.marines.com/request-information/WRRFY22MAXUSMCQUIZSITE";

export const PostCampaign = () => {
  const { assetUrl } = useEvent();
  const isMobile = useIsMobile();
  const { logClick } = useAnalytics();
  return (
    <>
      <Layout>
        <Container>
          <Title>REGISTRATION IS CLOSED</Title>
        </Container>
        <ArtistContainerMobile
          style={{
            backgroundImage: `url(${assetUrl("hero_mobile_0116251207.jpg")})`,
            width: "100%",
            margin: 0,
            alignSelf: "center",
          }}
        />
        <PostCampaignImage
          style={{
            backgroundImage: `url(${!isMobile ? bannerDesktop : bannerMobile})`,
          }}
        />
        <LearnMoreCallout>
          <MainContainer>
            <ResponsiveH1>THINK YOU’RE MARINE MATERIAL?</ResponsiveH1>
            <ResponsiveBody>
              Ready to find out what it takes to become a Marine, and what it
              means for our Nation to be one? Talking to a Marine Recruiter can
              help determine not only if you're right for the Marine Corps, but
              if the Marine Corps is right for you.
            </ResponsiveBody>
            <Button
              onClick={() => {
                window.open(learnMoreUrl, "_blank", "noopener");
                logClick(
                  { label: "Learn More", url: learnMoreUrl },
                  "external"
                );
              }}
              variant="secondary"
            >
              Learn More
            </Button>
          </MainContainer>
        </LearnMoreCallout>
      </Layout>
    </>
  );
};

const PostCampaignImage = styled.div`
  width: 100%;
  aspect-ratio: 2.1;
  background-size: cover;
  background-repeat: no-repeat;

  ${({ theme }) => theme.media.mobile} {
    aspect-ratio: 0.859;
    max-width: 390px;
    align-self: center;
    order: 3;
  }
`;

const LearnMoreCallout = styled.div`
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  ${MainContainer} {
    gap: ${(p) => p.theme.pxCalc("14px", "mobile")};
  }
`;

const Container = styled(MainContainer)`
  gap: 32px;
  flex: 0 0;

  b {
    font-weight: 700;
  }
  ${Divider} {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.mobile} {
    gap: ${(p) => p.theme.pxCalc("16px", "mobile")};
    padding-bottom: 20px;
    h1 {
      margin-bottom: 0;
    }

    ${Divider} {
      display: flex;
      margin-top: ${(p) => p.theme.pxCalc("8px", "mobile")};
      margin-bottom: ${(p) => p.theme.pxCalc("8px", "mobile")};
    }

    ${Button} {
      margin-top: ${(p) => p.theme.pxCalc("24px", "mobile")};
    }
  }

  ${({ theme }) => theme.media.desktop} {
    padding-top: ${(p) => p.theme.pxCalc("64px")};
    padding-bottom: ${(p) => p.theme.pxCalc("100px")};
    padding-left: ${(p) => p.theme.pxCalc("44px")};
    padding-right: ${(p) => p.theme.pxCalc("44px")};
    max-width: 100%;
    h1 {
      text-align: center;
    }
  }
`;
