import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import { fonts } from "theme/fonts";

export const BrandCTA = () => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event.meta.storageDirectory;

  const {
    adImage,
    btnLink,
    btnText,
    heading,
    logo,
  } = event.callToActions.brand;

  let brandLogo = `${api}/o/${storageDirectory}%2F${logo}?alt=media`;
  let bgDesktop = `${api}/o/${storageDirectory}%2F${adImage.desktop}?alt=media`;
  let bgMobile = `${api}/o/${storageDirectory}%2F${adImage.mobile}?alt=media`;

  const adClickHandler = () => {
    logClick({
      label: "Brand Ad",
      url: btnLink,
    });
  };

  return (
    <BrandAdWrapper>
      <Background bgDesktop={bgDesktop} bgMobile={bgMobile}>
        <Container>
          <Content>
            <img src={brandLogo} alt="" />
            <h2>{ReactHtmlParser(heading)}</h2>
            <a
              href={btnLink}
              onClick={adClickHandler}
              target="_blank"
              rel="noopener noreferrer"
            >
              {btnText}
            </a>
          </Content>
        </Container>
      </Background>
    </BrandAdWrapper>
  );
};

const BrandAdWrapper = styled.div`
  padding: 15px;
  background-color: #ffffff;
`;

const Background = styled.div`
  border-radius: 5px;
  background-image: url(${(props) => props.bgMobile});
  background-size: cover;
  background-position: center;

  @media ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.bgDesktop});
  }
`;

const Container = styled(MaxWidthContainer)`
  padding: 15px;
  display: flex;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 50px 25px;
  }
`;

const Content = styled.div`
  background-color: rgb(0 0 0 / 35%);
  padding: 25px;
  border-radius: 5px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  width: 100%;

  @media ${(props) => props.theme.maxMedia.small} {
    width: unset;
  }

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 25px 50px;
  }

  img {
    width: 100%;
    max-width: 100px;
  }

  h2 {
    line-height: 1.15;

    span {
      display: block;
    }

    span:nth-of-type(1) {
      font-size: clamp(36px, 9vw, 56px);
      letter-spacing: 8px;
    }

    span:nth-of-type(2) {
      font-family: var(--font-family-default);
      font-weight: 700;
      letter-spacing: 6px;
    }
  }

  a {
    background-color: #fec82e;
    color: #ffffff;
    padding: 10px 35px;
    text-decoration: none;
    border-radius: 5px;
    font-family: ${fonts.header};
    font-size: clamp(18px, 3vw, 24px);
    letter-spacing: 2px;
  }

  a:hover,
  a:focus {
    background-color: #ebaa00;
  }
`;
