import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 532px;
  margin: 0 auto;
  padding: 0 64px;

  ${({ theme }) => theme.media.mobile} {
    padding: 0 32px;
    max-width: 390px;
  }
`;
