import styled from "styled-components";
type ButtonProps = {
  variant?: "primary" | "secondary";
};
export const Button = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  background-color: transparent;
  border: 1px solid ${(p) => `var(--color-action-${p.variant || "primary"})`};
  border-radius: 4px;
  cursor: pointer;
  outline: 0;

  color: ${(p) => `var(--color-action-${p.variant || "primary"})`};
  font-family: var(--font-family-default);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  width: 328px;
  height: 50px;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;
