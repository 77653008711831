import { useEffect, useState } from "react";
import { useTheme } from "styled-components";

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

export const useIsMobile = () => {
  const {
    // @ts-ignore
    mediaQueryBreakpoints: { mobile },
  } = useTheme();
  return useMediaQuery(mobile);
};

export const useIsDesktop = () => {
  const {
    // @ts-ignore
    mediaQueryBreakpoints: { desktop },
  } = useTheme();
  return useMediaQuery(desktop);
};
