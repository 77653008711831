import styled from "styled-components";

export const ArtistContainerMobile = styled.div`
  display: flex;
  background-repeat: no-repeat;
  background-position: center 0%;
  background-size: contain;
  aspect-ratio: 0.678;
  width: calc(100% + 64px);
  margin: 0 -32px;
  max-width: 390px;

  ${({ theme }) => theme.media.desktop} {
    display: none;
  }
`;
