import React, { createContext, useState, useEffect } from "react";
import { Message } from "notes";
import styled from "styled-components";

export const MessageContext = createContext();
export const MessageConsumer = MessageContext.Consumer;
export const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState({});
  const displayMessage = (options) => {
    setMessage({ open: true, ...options });
  };
  const setOpen = () => {
    setMessage({});
  };
  useEffect(() => {
    let to;
    if (message.timeout) {
      setTimeout(() => setOpen(), message.timeout);
    }
    return () => {
      if (to) {
        clearTimeout(to);
      }
    };
  }, [message]);

  return (
    <MessageContext.Provider value={{ displayMessage }}>
      <MessageStyle {...message} setOpen={setOpen} />
      {children}
    </MessageContext.Provider>
  );
};

const MessageStyle = styled(Message)`
  ${(props) => props.theme.media.mobile} {
    align-items: center;
    padding-right: 72px;
    & > svg {
      right: 28px;
      width: 15px;
      height: 15px;
    }
  }
`;
