import styled from "styled-components";

export const ButtonPrimary = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  background-color: transparent;
  border: 1px solid var(--color-action-primary);
  border-radius: 4px;
  cursor: pointer;
  outline: 0;

  color: var(--color-action-primary);
  font-family: var(--font-family-default);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  width: 328px;
  height: 50px;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;
