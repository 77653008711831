import styled, { css } from "styled-components";

interface CheckboxProps
  extends Omit<React.HTMLAttributes<HTMLLabelElement>, "onChange"> {
  onChange: (checked: boolean, e: any) => void;
  title?: string;
  checked?: boolean;
  disabled?: boolean;
  hideCheck?: boolean;
  error?: boolean;
}

export const Checkbox = ({
  onChange,
  title,
  checked,
  disabled,
  hideCheck = false,
  error,
  ...props
}: CheckboxProps) => {
  return (
    <Wrapper {...props}>
      <CheckboxContainer>
        <HiddenCheckbox
          checked={checked}
          onChange={(e) => onChange(e.target.checked, e)}
          disabled={disabled}
        />
        {!hideCheck && (
          <StyledCheckbox checked={checked} error={error}>
            <Icon viewBox="0 0 8 8">
              <rect width="8" height="8" fill="#4d4d4d" />
            </Icon>
          </StyledCheckbox>
        )}
      </CheckboxContainer>
      {!!title && <CheckboxTitle>{title}</CheckboxTitle>}
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: flex;
  padding: 0;
`;

const CheckboxContainer = styled.div`
  margin: 4px 10px 0 0;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const Icon = styled.svg``;

export const CheckboxTitle = styled.span`
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  color: var(--color-text-default);
  font-family: var(--font-family-default);

  a {
    color: var(--color-text-default);
  }
`;

const StyledCheckbox = styled.div<Pick<CheckboxProps, "checked" | "error">>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 4px;
  width: 19px;
  height: 19px;

  input[disabled] + & {
    background: #dcdcdc;
    border-color: #dcdcdc;
  }

  ${Icon} {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};

    path {
      fill: #000;
    }
  }

  ${(props) =>
    props.error &&
    css`
      border: 2px solid var(--color-border-error);
      background: var(--color-fill-error);
    `}
`;
