import React, { useState } from "react";
import styled, { css } from "styled-components";
import { InputPlaceholder } from "./Form";
import { SvgDropdown } from "assets";
import { useClickOutside } from "hooks/useClickOutside";

const IconContainer = styled.div`
  color: ${(props) => props.theme.input.placeholder.color};
  position: absolute;
  top: 10px;
  left: 10px;
  width: 16px;
  height: 16px;
`;

export const Caret = styled(SvgDropdown)`
  position: absolute;
  right: 14px;
  top: 14px;
  width: 16px;
  height: 16px;
  z-index: 1;
  path {
    fill: white;
  }
`;

type SelectFieldProps = {
  icon?: boolean;
  open?: boolean;
  error?: boolean;
  disabled?: boolean;
};

const SelectField = styled.div<SelectFieldProps>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  height: 44px;

  appearance: none;
  background-color: #ffffff;
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius);
  outline: 0;
  z-index: 1;

  font-family: var(--font-family-default);
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-input);

  ${(props) =>
    props.open &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      /* border-bottom: none; */
      box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
      z-index: 4;
      /* &::after {
        content: "";
        background-color: white;
        position: absolute;
        top: calc(100%);
        left: 0;
        width: 100%;
        height: 1px;
        z-index: 5;
      } */
    `};
  &:focus {
    border-color: white;
    border-bottom-color: white;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${props.theme.input.disabled.backgroundColor};
      border-color: ${props.theme.input.disabled.borderColor};
      pointer-events: none;
      ${IconContainer} {
        color: ${props.theme.input.disabled.placeholder.color};
      }
    `};
  ${(props) =>
    props.error &&
    css`
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: ${props.icon ? "34px" : "11px"};
      padding-right: 29px;
      background-color: white;
      ${IconContainer} {
        top: 9px;
        left: 9px;
      }
      ${Caret} {
        top: 10px;
        right: 10px;
      }
    `};
`;

type DropdownProps = {
  open?: boolean;
};

const Dropdown = styled.div<DropdownProps>`
  position: absolute;
  display: block;
  top: 100%;
  left: 1px;
  right: 1px;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.34);
  max-height: 224px;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
  pointer-events: none;
  z-index: 3;
  ${(props) =>
    props.open &&
    `opacity: 1;
    visibility: visible;
    pointer-events: all;`}
`;

type ItemProps = {
  selected?: boolean;
  paddingChange?: boolean;
  twoLine?: boolean;
};

const Item = styled.div<ItemProps>`
  align-items: flex-start;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.selectItemHover : "#FFFFFF"};
  border-bottom-style: solid;
  border-bottom-color: white;
  border-bottom-width: 1px;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: flex;
  ${(props) => props.theme.fonts.default}
  flex-flow: column wrap;
  font-weight: ${(props) => (props.selected ? 500 : 300)};
  justify-content: ${(props) => (props.twoLine ? "flex-start" : "center")};
  line-height: 22px;
  ${(props) => props.paddingChange && `min-height: 71px;`};
  position: relative;
  padding: ${(props) =>
    props.paddingChange ? "15px 10px 15px 67px" : "17px 10px"};
  font-size: 15px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const SubTitle = styled.small`
  display: block;
  margin-top: 4px;
  width: 100%;
  font-size: 11px;
  line-height: 14px;
  font-weight: 300;
`;

type SelectedProps = {
  withImage?: boolean;
  placeholderValue?: boolean;
  disabled?: boolean;
};

const Selected = styled.span<SelectedProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.withImage ? "flex-start" : "space-between"};
  overflow: hidden;
  position: relative;
  z-index: 6;
  user-select: none;
  pointer-events: none;

  font-family: var(--font-family-default);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-input);
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) =>
    props.disabled &&
    `color: ${
      props.placeholderValue
        ? props.theme.input.disabled.placeholder.color
        : props.theme.input.disabled.color
    };`}
`;

type SelectProps = {
  options?: Array<{
    text: string;
    image?: string;
    id: string;
    subtext?: string;
  }>;
  disabled?: boolean;
  icon?: React.ReactNode;
  placeholder?: string;
  selected?: {
    text: string;
    id: string;
    image?: string;
  };
  onChange: (val: any) => void;
  onOpen?: (val: boolean) => void;
  error?: boolean;
};

export const Select = ({
  options = [],
  disabled,
  icon,
  placeholder,
  selected,
  onChange,
  onOpen,
  error,
  ...props
}: SelectProps) => {
  const [openState, setOpenState] = useState(false);
  const [fieldRef, setFieldRef] = useState<HTMLDivElement | null>(null);

  const handleOpenState = (val: boolean) => {
    setOpenState(val);
    !!onOpen && onOpen(val);
  };

  const handleClickOutside = (evt: MouseEvent) => {
    if (!fieldRef?.contains(evt.target as Node)) {
      handleOpenState(false);
    }
  };

  useClickOutside(fieldRef, handleClickOutside);

  return (
    <FieldWrapper ref={setFieldRef}>
      <InputPlaceholder
        error={error}
        isOffset={!!selected}
        style={{ zIndex: 5 }}
      >
        {placeholder}
      </InputPlaceholder>
      <SelectField
        open={openState}
        icon={!!icon}
        disabled={disabled}
        {...props}
        onClick={() => handleOpenState(!openState)}
      >
        {!!icon && <IconContainer>{icon}</IconContainer>}
        {selected && (
          <Selected
            disabled={disabled}
            withImage={!!selected.image}
            placeholderValue={selected.id ? false : true}
          >
            {!!selected.image ? (
              <>
                <SelectedImageMask
                  style={{ background: `url(${selected.image})` }}
                />
                {selected.text.toUpperCase()}
              </>
            ) : (
              selected.text
            )}
          </Selected>
        )}
        <Caret />
      </SelectField>

      <Dropdown open={openState}>
        {options.map((option) => {
          const { text, image, id, subtext } = option;
          return (
            <Item
              key={id}
              selected={id === selected?.id}
              onClick={() => {
                onChange(option);
                handleOpenState(false);
              }}
              paddingChange={!!image}
              twoLine={!!subtext}
            >
              {!!subtext ? (
                <>
                  {!!image && (
                    <LabelImageContainer>
                      <LabelImage src={image} />
                    </LabelImageContainer>
                  )}
                  {text}
                  <SubTitle>{subtext}</SubTitle>
                </>
              ) : (
                <>
                  {!!image && (
                    <LabelImageContainer>
                      <LabelImage src={image} />
                    </LabelImageContainer>
                  )}
                  {text}
                </>
              )}
            </Item>
          );
        })}
      </Dropdown>
    </FieldWrapper>
  );
};

const SelectedImageMask = styled.div`
  background-size: cover;
  background-position: center center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 12px;
`;

const LabelImageContainer = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  height: 40px;
  width: 40px;
  position: absolute;
  left: 12px;
`;

const LabelImage = styled.img`
  display: block;
  height: auto;
  width: 76px;
  position: relative;
`;

const FieldWrapper = styled.div`
  background: transparent;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;
