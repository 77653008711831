import { Layout, MainContainer } from "Layout";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { questions } from "./questions";
import { questionImages } from "assets/select-options";
import {
  ResponsiveBody,
  FormField,
  H2,
  handleErrors,
  ResponsiveH1,
} from "Components";
import { FormErrors, RegisterForm } from "./RegisterForm";
import { SvgBackArrow } from "assets";
import parse from "react-html-parser";
import { useAnalytics, useEvent, useUser } from "context";
import { ArtistContainerMobile } from "Layout";

export type StringRecord = {
  [key: string]: string;
};

export const Register = () => {
  const [registration, setRegistration] = useState<StringRecord>({});
  const [questionIndex, setQuestionIndex] = useState(0);
  const [errors, setErrors] = useState<FormErrors>({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isTimeoutActive, setIsTimeoutActive] = useState(false);

  const { writeToUserSubmissionDoc } = useUser();
  const { event, assetUrl } = useEvent();
  const { logLeadSubmission, logClick } = useAnalytics();

  const isQuizComplete = questionIndex > questions.length - 1;
  const currentQuestion = questions[questionIndex];
  const fields = event.register.fields as FormField[];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [questionIndex]);

  const handleNext = (answer?: string) => {
    if (isTimeoutActive) return;
    setIsTimeoutActive(true);
    const timeout =
      currentQuestion.name in registration &&
      registration[currentQuestion.name] === answer
        ? 0
        : 1000;
    setTimeout(() => {
      setQuestionIndex((prev) => prev + 1);
      setIsTimeoutActive(false);
    }, timeout);
  };

  const handleBack = () => {
    setQuestionIndex((prev) => prev - 1);
  };

  const handleSubmit = async () => {
    linkClickHandler("Submit Quiz button", "Submit Quiz", "internal");
    setSubmitting(true);
    const errorObject = handleErrors(fields, registration, 0);
    if (!errorObject) {
      await writeToUserSubmissionDoc({
        ...registration,
      });
      logLeadSubmission({ newFormPart: 1, registration });
    } else {
      setSubmitting(false);
      setErrors(errorObject as FormErrors);
    }
  };

  const handleChange = (value: StringRecord, name: string) => {
    setRegistration((prev) => {
      const update = { ...prev, ...value };
      return update;
    });
    if (errors?.[name]) {
      setErrors((prev) => {
        delete prev[name];
        return prev;
      });
    }
  };

  const linkClickHandler = (url: string, label: string, category: string) => {
    logClick({ label, url }, category);
  };

  return (
    <Layout>
      <StyledMainContainer extraPadding={[1, 2, 3].includes(questionIndex)}>
        {questionIndex === 0 && (
          <>
            <Title>What Character Traits Do You Share with Marines?</Title>
            <ArtistContainerMobile
              style={{
                backgroundImage: `url(${assetUrl(
                  "hero_mobile_0116251207.jpg"
                )})`,
              }}
            />
            <Container>
              <Divider />
              <Details>
                <H2>
                  Take the Marine Character Quiz and enter for a chance to win
                  signed merchandise from Shaylen.
                </H2>
                <ResponsiveBody>
                  Every Marine fights for a collective purpose- to protect our
                  Nation's future. The United States Marine Corps is a family
                  that fights together, and all Marines share character traits
                  that our Nation can count on. Which character traits do you
                  share with Marines?
                  <br />
                  <br />
                  Take the Marine Character Quiz to find out!
                </ResponsiveBody>
              </Details>
            </Container>
          </>
        )}
        {isQuizComplete ? (
          <ContentContainer isFormVisible>
            <H2>
              Submit the form to get your results and enter for a chance to win
              Shaylen's signed merch.
            </H2>
            <RegisterForm
              {...{
                onSubmit: handleSubmit,
                onChange: handleChange,
                fields,
                registration,
                errors,
                submitting,
              }}
            />
          </ContentContainer>
        ) : (
          <ContentContainer>
            <H2>{parse(currentQuestion.question)}</H2>
            <ResponsiveBody>(Select one)</ResponsiveBody>
            <QuizOptions>
              {currentQuestion.answers.map((option, index) => {
                const imagePath =
                  questionImages[
                    `question_${questionIndex + 1}_${
                      option.value
                    }` as keyof typeof questionImages
                  ];
                return (
                  <QuizOption
                    key={index}
                    selected={
                      registration[currentQuestion.name] === option.value
                    }
                    background={imagePath}
                    onClick={() => {
                      setRegistration((res) => ({
                        ...res,
                        [currentQuestion.name]: option.value,
                      }));
                      handleNext(option.value);
                    }}
                  >
                    {option.text}
                  </QuizOption>
                );
              })}
            </QuizOptions>
          </ContentContainer>
        )}
        {questionIndex > 0 && questionIndex < 4 && (
          <BackButton onClick={handleBack}>
            <SvgBackArrow />
            Back
          </BackButton>
        )}
      </StyledMainContainer>
    </Layout>
  );
};

const StyledMainContainer = styled(MainContainer)<{ extraPadding?: boolean }>`
  padding-bottom: ${(p) => p.theme.pxCalc("64px")};
  ${({ theme }) => theme.media.desktop} {
    padding-bottom: ${(p) => p.theme.pxCalc("40px")};
    ${(p) =>
      p.extraPadding &&
      css`
        padding-top: ${(p) => p.theme.pxCalc("60px")};
        padding-bottom: ${(p) => p.theme.pxCalc("120px")};
      `};
  }
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  font-family: var(--font-family-default);
  font-weight: 300;
  margin-top: ${(p) => p.theme.pxCalc("36px")};
  user-select: none;

  ${({ theme }) => theme.media.mobile} {
    margin-top: ${(p) => p.theme.pxCalc("24px", "mobile")};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${H2} {
    font-size: ${(p) => p.theme.pxCalc("24px", "mobile")};
    line-height: ${(p) => p.theme.pxCalc("28px", "mobile")};
  }
  ${({ theme }) => theme.media.desktop} {
    ${H2} {
      font-size: ${(p) => p.theme.pxCalc("24px")};
      line-height: ${(p) => p.theme.pxCalc("28px")};
    }
  }
`;

type QuizOptionType = {
  selected?: boolean;
  background?: string;
};

const QuizOption = styled.div<QuizOptionType>`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  width: 100%;
  height: ${(p) => p.theme.pxCalc("100px")};

  font-family: var(--font-family-default);
  font-weight: 700;
  font-size: ${(p) => p.theme.pxCalc("24px")};
  line-height: ${(p) => p.theme.pxCalc("28px")};
  text-align: center;
  color: #f2f3f5;
  cursor: pointer;
  user-select: none;

  ${(p) =>
    !p.selected
      ? css`
          background-image: ${`url(${p.background})`};
        `
      : css`
          background: #fff;
          color: #000;
          border: 2px solid #fff;
        `};

  ${({ theme }) => theme.media.mobile} {
    font-size: ${(p) => p.theme.pxCalc("20px", "mobile")};
    line-height: ${(p) => p.theme.pxCalc("24px", "mobile")};
    height: ${(p) => p.theme.pxCalc("75px", "mobile")};
    padding: 0 20px;
  }
`;

const QuizOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(p) => p.theme.pxCalc("36px")};

  ${({ theme }) => theme.media.mobile} {
    gap: ${(p) => p.theme.pxCalc("36px", "mobile")};
  }
`;

type ContentContainerType = {
  extraPadding?: boolean;
  isFormVisible?: boolean;
};

const ContentContainer = styled.div<ContentContainerType>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: ${(p) =>
    p.isFormVisible ? p.theme.pxCalc("32px") : p.theme.pxCalc("40px")};
  gap: ${(p) =>
    p.isFormVisible ? p.theme.pxCalc("32px") : p.theme.pxCalc("22px")};
  width: 100%;

  ${H2} {
    font-size: ${(p) => p.theme.pxCalc("24px")};
    line-height: ${(p) => p.theme.pxCalc("28px")};
    text-align: center;
    width: ${(p) => (p.isFormVisible ? p.theme.pxCalc("382px") : "95%")};
    margin: 0 auto;
  }
  ${ResponsiveBody} {
    text-align: center;
    width: 100%;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-top: ${(p) =>
      p.isFormVisible
        ? p.theme.pxCalc("22px", "mobile")
        : p.theme.pxCalc("40px", "mobile")};

    max-width: 100%;
    gap: ${(p) => p.theme.pxCalc("22px", "mobile")};
    ${H2} {
      font-size: ${(p) => p.theme.pxCalc("24px", "mobile")};
      line-height: ${(p) => p.theme.pxCalc("28px", "mobile")};
      width: 100%;
    }
    ${ResponsiveBody} {
      width: 100%;
    }
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.pxCalc("26px", "mobile")};

  ${({ theme }) => theme.media.mobile} {
    gap: ${(p) => p.theme.pxCalc("26px", "mobile")};
    margin-top: ${(p) => p.theme.pxCalc("22px", "mobile")};
  }
`;

export const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #efc97b;
  margin-top: ${(p) => p.theme.pxCalc("26px")};
  margin-bottom: ${(p) => p.theme.pxCalc("26px")};

  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

export const Title = styled(ResponsiveH1)`
  color: var(--text-default);
  margin-top: ${(p) => p.theme.pxCalc("30px")};

  ${({ theme }) => theme.media.mobile} {
    margin-top: ${(p) => p.theme.pxCalc("22px", "mobile")};
    margin-bottom: ${(p) => p.theme.pxCalc("16px", "mobile")};
  }
`;
