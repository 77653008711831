import styled from "styled-components";

export const H1 = styled.h1`
  font-family: var(--font-family-default);
  font-size: 36px;
  font-weight: 700;
  line-height: 38px;
  text-transform: uppercase;

  ${({ theme }) => theme.media.mobile} {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const ResponsiveH1 = styled.h1`
  font-family: var(--font-family-default);
  font-size: ${(p) => p.theme.pxCalc("36px")};
  line-height: ${(p) => p.theme.pxCalc("38px")};
  font-weight: 700;
  text-transform: uppercase;

  ${({ theme }) => theme.media.mobile} {
    font-size: ${(p) => p.theme.pxCalc("32px", "mobile")};
    line-height: ${(p) => p.theme.pxCalc("38px", "mobile")};
  }
`;

export const H2 = styled.h2`
  font-family: var(--font-family-default);
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;

export const ResponsiveH2 = styled.h2`
  font-family: var(--font-family-default);
  font-weight: 700;
  font-size: ${(p) => p.theme.pxCalc("24px")};
  line-height: ${(p) => p.theme.pxCalc("28px")};

  ${({ theme }) => theme.media.mobile} {
    font-size: ${(p) => p.theme.pxCalc("24px", "mobile")};
    line-height: ${(p) => p.theme.pxCalc("28px", "mobile")};
  }
`;

export const Body = styled.p`
  font-family: var(--font-family-default);
  font-weight: var(--font-weight-default);
  font-size: 14px;
  line-height: 20px;
`;

export const ResponsiveBody = styled.p`
  font-family: var(--font-family-default);
  font-weight: var(--font-weight-default);
  font-size: ${(p) => p.theme.pxCalc("14px")};
  line-height: ${(p) => p.theme.pxCalc("20px")};

  ${({ theme }) => theme.media.mobile} {
    font-size: ${(p) => p.theme.pxCalc("14px", "mobile")};
    line-height: ${(p) => p.theme.pxCalc("20px", "mobile")};
  }
`;
