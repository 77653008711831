import styled, { css } from "styled-components";
import { Select as Select2 } from "Components/Select";
import { FieldMessage } from "Components";

export const Select = ({ onChange, value, field, error = {} }) => {
  let selected = value && field.options.find((option) => option.id === value);

  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };

  return (
    <>
      <StyledSelect
        placeholder={field.placeholder ?? "Select an option..."}
        selected={selected}
        options={field.options}
        error={!!error.status}
        onChange={(val) => handleChange(val.id)}
      />
      {error.status && (
        <FieldMessage
          active={value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};

const StyledSelect = styled(Select2)`
  & > svg > path {
    fill: var(--color-text-disabled);
  }

  ${(props) =>
    props.error &&
    css`
      background-color: white;
      border: 2px solid var(--color-border-error);

      span {
        color: #6d2621;
      }

      &:focus {
        border-width: 1px;
      }
    `};
`;
