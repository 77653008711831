import { ReactComponent as SvgCheck } from "./Check.svg";
import { SvgDropdown } from "assets";
import { useState } from "react";
import styled, { css } from "styled-components";
import { useClickOutside } from "hooks/useClickOutside";

const Caption = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
`;

const Selected = styled.p`
  font-family: var(--font-family-default);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const Body1 = styled.p`
  font-family: var(--font-family-default);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const Body2 = styled.p`
  font-family: var(--font-family-default);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

const Label = styled.label`
  display: block;
  font-family: var(--font-family-default);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export type SelectOption = {
  label: React.ReactNode;
  caption?: React.ReactNode;
  value: string;
  disabled?: boolean;
};

interface InternalSelectProps {
  label?: string;
  onChange: (v: string) => void;
  value: string;
  options: SelectOption[];
  placeholder?: string;
  style?: React.CSSProperties;
  helperText?: string;
  hasError?: boolean;
}

export const InternalSelect = ({
  label,
  onChange,
  value,
  options,
  placeholder,
  helperText,
  hasError,
  ...props
}: InternalSelectProps) => {
  const [fieldRef, setFieldRef] = useState<HTMLDivElement | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const selected: SelectOption | undefined = options?.find(
    (option) => option.value === value
  );

  const handleClickOutside = (evt: MouseEvent) => {
    if (!fieldRef?.contains(evt.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  useClickOutside(fieldRef, handleClickOutside);

  return (
    <SelectWrapper {...props}>
      {label && <Label>{label}</Label>}
      <SelectedValue
        onClick={() => {
          setIsMenuOpen(true);
        }}
        isOpen={isMenuOpen}
      >
        <TextContainer>
          {selected ? (
            <Selected>{selected?.label}</Selected>
          ) : (
            <Body2>{placeholder}</Body2>
          )}
        </TextContainer>
        <Dropdown />
      </SelectedValue>
      <SelectMenu isOpen={isMenuOpen} ref={setFieldRef}>
        {options.map((option, idx) => (
          <ListItemComponent
            label={option.label}
            disabled={option.disabled}
            caption={option.caption}
            key={`select-option-${idx}`}
            selected={selected?.value === option.value}
            onClick={() => {
              if (option.disabled) {
                return;
              }

              setIsMenuOpen(false);
              onChange(option.value);
            }}
          />
        ))}
      </SelectMenu>
    </SelectWrapper>
  );
};

type ListItemComponentProps = Pick<
  SelectOption,
  "label" | "caption" | "disabled"
> & {
  selected?: boolean;
  onClick?: VoidFunction;
};

const ListItemComponent = ({
  selected,
  label,
  caption,
  disabled,
  ...props
}: ListItemComponentProps) => {
  return (
    <SelectListItem {...props} $disabled={disabled}>
      <ListItemRow>
        {selected && (
          <CheckIconWrapper isSelected={selected}>
            <SvgCheck />
          </CheckIconWrapper>
        )}
        <Body1>{label}</Body1>
      </ListItemRow>
      {caption && <Caption>{caption}</Caption>}
    </SelectListItem>
  );
};

const TextContainer = styled.span`
  flex-grow: 1;
  overflow: hidden;
  ${Selected} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: -2px;
  }
`;
type SelectedValueProps = {
  hasError?: boolean;
  isOpen?: boolean;
};
const SelectedValue = styled.div<SelectedValueProps>`
  display: flex;
  width: 100%;
  height: 32px;
  background: #fff;
  border-radius: 6px;
  border: solid 1px #ccc;
  padding: 6px 12px;
  user-select: none;
  cursor: pointer;
  align-items: center;

  ${Body2} {
    color: #999;
  }

  ${(p) =>
    p.hasError &&
    css`
      border: solid 1px ${(p) => p.theme.colors.error};
    `};

  ${(p) =>
    p.isOpen &&
    css`
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
    `};
`;

const ListItemRow = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  ${Body1} {
    color: #333;
    margin-top: -2px;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 12px 9px 44px;
  position: relative;
  user-select: none;
`;

const SelectListItem = styled(ListItem)<{ $disabled?: boolean }>`
  :hover {
    cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
    background: #f2f5f7;
  }
  & + & {
    border-top: solid 1px rgba(0, 0, 0, 0.15);
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      ${Body1} {
        color: #999;
      }
      path {
        color: #999;
      }
    `};
`;

type CheckIconProps = {
  isSelected: boolean;
};

const CheckIconWrapper = styled.div<CheckIconProps>`
  display: flex;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 12px;
  ${(p) =>
    p.isSelected
      ? css`
          color: #01207e;
        `
      : css`
          opacity: 0;
        `}
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${Label} {
    margin-bottom: 4px;
  }
`;

const Dropdown = styled(SvgDropdown)`
  color: #666;
`;

const SelectMenu = styled.div<{ isOpen?: boolean }>`
  background-color: #fff;
  color: #333;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 250px;
  z-index: 10000000000;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  top: -150px;
  display: none;
  flex-direction: column-reverse;

  ${(p) => p.isOpen && "display: block;"};
`;
