import { Button, Field, SpanType, FormField } from "Components";
import styled from "styled-components";
import { StringRecord } from "./Register";
import { SvgLoading } from "assets";

export type FieldError = {
  error: boolean;
  message: string;
  label: string;
};

export type FormErrors = { [key: string]: FieldError };

type RegisterFormProps = {
  registration: StringRecord;
  errors: FormErrors;
  onSubmit: VoidFunction;
  onChange: (value: StringRecord, name: string) => void;
  fields?: FormField[];
  submitting?: boolean;
};

export const RegisterForm = ({
  registration,
  errors,
  onChange,
  onSubmit,
  fields,
  submitting,
}: RegisterFormProps) => {
  return (
    <Wrapper>
      {fields
        ?.sort((a, b) => a.order - b.order)
        .map((field, index) => {
          return (
            <FieldContainer
              key={index}
              span={field.span}
              spanMobile={field.spanMobile}
            >
              <Field
                field={field}
                value={registration[field.name]}
                onChange={(val: StringRecord) => onChange(val, field.name)}
                error={{
                  status: errors[field.name],
                  message: errors[field.name]?.message,
                }}
              />
            </FieldContainer>
          );
        })}
      <Button
        tabIndex={0}
        onClick={() => {
          onSubmit();
        }}
        disabled={submitting}
        style={{ position: "relative" }}
      >
        {submitting && <LoadingSpinner />}
        {submitting ? "Sending..." : "Submit"}
      </Button>
    </Wrapper>
  );
};

const LoadingSpinner = styled(SvgLoading)`
  width: 16px;
  height: 16px;
  position: absolute;
  margin: 0 0 -2px -104px;
`;

type SpanOptions = {
  span?: SpanType;
  spanMobile?: SpanType;
};

const FieldContainer = styled.div<SpanOptions>`
  display: flex;
  flex-direction: column;
  grid-column: ${({ span }) => (span === "half" ? "span 1" : "span 2")};

  ${({ theme }) => theme.media.mobile} {
    grid-column: ${({ spanMobile }) =>
      spanMobile === "half" ? "span 1" : "span 2"};
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  column-gap: 22px;
  row-gap: 24px;
  width: 100%;
  position: relative;

  ${Button} {
    width: 80%;
    grid-column: 1 / span 2;
    justify-self: center;
  }

  ${({ theme }) => theme.media.mobile} {
    ${Button} {
      width: 100%;
    }
  }
`;
