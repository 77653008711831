import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

export const MessageField = ({ field, error = {}, ...props }) => {
  return <Label {...props}>{ReactHtmlParser(field.message)}</Label>;
};

const Label = styled.p`
  color: var(--color-text-default);
  font-size: 10px;
  font-weight: var(--font-weight-default);
  line-height: 16px;
  a,
  a:hover {
    color: #ffffff;
    text-decoration: underline;
  }
`;
