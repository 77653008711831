import { useContext, useEffect, useState } from "react";
import { EventContext, UserContext, TimeContext, UserType } from "context";
import { PreCampaign } from "./PreCampaign";
import { PostCampaign } from "./PostCampaign";
import { AdminControls, RingSpinner } from "Components";
import { Register } from "./Register/Register";
import { Results } from "./Results";
import { useHistory } from "react-router-dom";

export const Root = () => {
  const { event } = useContext(EventContext);
  const { time } = useContext(TimeContext);
  const { user, userType } = useContext(UserContext);
  const [adminView, setAdminView] = useState("");
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = urlParams.get("view");

  useEffect(() => {
    if (userType === UserType.ADMIN && !adminView) {
      setAdminView(params ? params : "precampaign");
      !params && history.push(`?view=precampaign`);
    }
    // eslint-disable-next-line
  }, [userType]);

  if (!user) return <RingSpinner />;

  const views = ["precampaign", "sweeps", "registered", "postcampaign"];

  let currentView = "";
  if (event.campaignStart > time) {
    currentView = "precampaign";
  } else if (event.campaignEnd < time) {
    currentView = "postcampaign";
  } else if (userType === UserType.REGISTERED) {
    currentView = "registered";
  } else {
    currentView = "sweeps";
  }

  if (userType === UserType.ADMIN && adminView) {
    currentView = adminView;
  }

  const renderComponent = (view: string) => {
    switch (view) {
      case "precampaign":
        return <PreCampaign />;
      case "sweeps":
        return <Register />;
      case "registered":
        return <Results />;
      case "postcampaign":
        return <PostCampaign />;
      default:
        return <PreCampaign />;
    }
  };

  return (
    <>
      {renderComponent(currentView)}
      {userType === UserType.ADMIN && (
        <AdminControls
          {...{
            selectedView: adminView,
            setAdminView,
            views,
          }}
        />
      )}
    </>
  );
};
