import { responsiveCalc } from "Modules";
import { fonts } from "./fonts";
// @ts-ignore
import { MAXTheme } from "notes";

export const mediaQueryBreakpoints = {
  mobile: "(max-width: 1023px)",
  tablet: "(min-width: 768px) and (max-width: 1023px)",
  desktop: "(min-width: 1024px)",
};

export const mediaQueries = {
  mobile: `@media all and ${mediaQueryBreakpoints.mobile}`,
  desktop: `@media all and ${mediaQueryBreakpoints.desktop}`,
};

export const theme = {
  ...MAXTheme,
  // overrides styled-component fonts
  fonts: {
    header: `font-family: ${fonts.header};`,
    default: `font-family: ${fonts.default};`,
  },
  // custom media queries
  maxMedia: {
    small: "(min-width: 768px)",
    medium: "(min-width: 950px)",
    large: "(min-width: 1240px)",
  },
  media: mediaQueries,
  mediaQueryBreakpoints,
  pxCalc: responsiveCalc,
};

export type ThemeType = typeof theme;
