import { useContext } from "react";
import styled from "styled-components";
import { EventContext } from "context";
import ReactHtmlParser from "react-html-parser";

export const Rules = () => {
  const { event } = useContext(EventContext);

  return (
    <Wrapper>
      <Main as="main">{ReactHtmlParser(event?.rules?.body)}</Main>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: #001235;
`;

const Main = styled.main`
  padding: 15px;

  p {
    margin-top: 16px;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
  strong {
    font-weight: 700;
  }

  a {
    color: #000000;
  }
`;
