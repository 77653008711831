import { LoadScriptProps, useLoadScript } from "@react-google-maps/api";
import { FieldMessage, FormField } from "Components";
import { Autocomplete, AutocompleteOption } from "Components/Autocomplete";
import { getAddressComponent } from "Components/Form/RegistrationForm/placesUtil";

export const googleGeocoder = async (placeId: string) => {
  const geocoder = new google.maps.Geocoder();
  const { results } = await geocoder.geocode({ placeId, language: "en" });
  return results;
};

export const googlePrediction = async (input: string) => {
  const autocompleteService = new google.maps.places.AutocompleteService();
  const { predictions } = await autocompleteService.getPlacePredictions(
    { input },
    (predictions, status) => {
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
        return [];
      }
      return predictions;
    }
  );
  return predictions;
};

type AddressFieldProps = {
  onChange: (val: { [key: string]: string }) => void;
  value?: string;
  field?: FormField;
  error?: any;
};

const libraries = ["places"] as LoadScriptProps["libraries"];

export const AddressField = ({
  onChange,
  value,
  field,
  error = {},
}: AddressFieldProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_APIKEY as string,
    libraries: libraries,
  });

  const handlePlacesChange = async (option: AutocompleteOption) => {
    const placeResult = await googleGeocoder(option.value);
    onChange({
      ADDRESS1: option.label,
      ADDRESS2: "",
    });
    const addressComps = getAddressComponent(placeResult[0], [
      "CITY",
      "STATE",
      "ZIP",
    ]);
    for (const key in addressComps) {
      if (addressComps?.[key]) {
        onChange({ [key]: addressComps[key].short_name });
      }
    }
  };

  const handleSearch = async (
    queryString: string
  ): Promise<AutocompleteOption[]> => {
    if (!isLoaded) return [];
    const results = await googlePrediction(queryString);

    return (
      results
        ?.filter((r) => !!r.structured_formatting.secondary_text)
        ?.map((c) => ({
          label: c.structured_formatting.main_text,
          value: c.place_id,
        })) || []
    );
  };

  return (
    <>
      <Autocomplete
        placeholder={field?.placeholder}
        value={value}
        onChange={(val) =>
          onChange({
            ADDRESS1: val,
          })
        }
        onSelect={(val) => handlePlacesChange(val)}
        search={handleSearch}
        error={!!error.status}
      />
      {error.status && (
        <FieldMessage
          active={!!value || !!error.status}
          content={error.message}
          type={error.status ? "error" : "success"}
        />
      )}
    </>
  );
};
