import { useContext } from "react";
import styled from "styled-components";
import { EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useMediaQuery } from "hooks/useMediaQuery";
import brandLogo from "assets/marines-logo.png";

library.add(fas, fab);

export const Header = ({ registration, preCampaign }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);
  const isMobile = useMediaQuery();

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <HeaderWrapper as="header" registration={registration}>
      <HeaderContainer preCampaign={preCampaign}>
        {(preCampaign || isMobile) && (
          <Link
            href={event?.logos?.link || "https://www.marines.com/"}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              linkClickHandler(brandLogo.link, "Marines Homepage", "header")
            }
          >
            <PrimaryLogo isMobile={isMobile} src={brandLogo} />
          </Link>
        )}
      </HeaderContainer>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  z-index: 1;
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 15px 0;
  background-color: #991b20;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 25px 0;
    flex-direction: row;
    background-color: #991b20;
  }
`;

const Link = styled.a`
  width: 100%;
  max-width: 200px;
`;

const Logo = styled.img`
  width: 100%;
  display: flex;
`;

const PrimaryLogo = styled(Logo)`
  ${(props) => props.isMobile && "width: 80%; margin: 0 auto;"}
`;
