import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { FieldMessage } from "Components/Form/index.js";
import { Input } from "Components/Form/Fields/Input";
import { useClickOutside } from "hooks/useClickOutside";

const NO_RESULTS_TEXT = "No options found...";

export type AutocompleteOption = {
  label: string;
  value: string;
  icon?: JSX.Element;
  data?: any;
};

type AutocompleteProps = {
  options?: any;
  onChange: (value: string) => void;
  onSelect: (option: AutocompleteOption) => void;
  search: any;
  error?: boolean;
  value?: string;
  Loading?: any;
  Result?: any;
  placeholder?: string;
};

export const Autocomplete = ({
  options,
  onChange,
  onSelect,
  search,
  error,
  value,
  Loading = LoadingDefault,
  Result = Item,
  ...props
}: AutocompleteProps) => {
  const [results, setResults] = useState<AutocompleteOption[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [fieldRef, setFieldRef] = useState<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let cancelled = false;
    if (value) {
      (async () => {
        setLoading(true);
        const results = await search(value);
        if (!cancelled) {
          setLoading(false);
          setResults(results);
        }
      })();
    } else {
      setLoading(false);
      setResults([]);
      setShowOptions(false);
    }
    return () => {
      cancelled = true;
    };
  }, [value]);

  const handleSelect = (result: AutocompleteOption) => {
    onSelect(result);
    setResults([]);
    setShowOptions(false);
  };

  const handleChange = (text: string) => {
    if (text) {
      setShowOptions(true);
    }
    onChange(text);
  };

  useClickOutside(fieldRef, () => {
    // setResults([]);
    setShowOptions(false);
  });

  return (
    <FieldWrapper ref={setFieldRef}>
      <StyledInput
        onChange={handleChange}
        value={value}
        open={showOptions}
        error={error}
        onFocus={() => {
          if (results.length) {
            setShowOptions(true);
          }
        }}
        {...props}
      />
      {loading ? (
        <StyledDropdown>
          <Loading />
        </StyledDropdown>
      ) : (
        showOptions && (
          <StyledDropdown>
            {results.length ? (
              results.map((result, index) => (
                <Result
                  key={`autocomplete-option-${index}`}
                  onClick={() => handleSelect(result)}
                  result={result}
                  tabIndex={0}
                  data-javelin-name={`change-location-select`}
                  data-javelin-meta={JSON.stringify(result)}
                >
                  {result.label}
                </Result>
              ))
            ) : (
              <Result result={{ text: NO_RESULTS_TEXT }}>
                {NO_RESULTS_TEXT}
              </Result>
            )}
          </StyledDropdown>
        )
      )}
    </FieldWrapper>
  );
};

const FieldWrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  label > & {
    margin-top: 9px;
  }
`;

type FieldProps = {
  open?: boolean;
};

const StyledInput = styled(Input)<FieldProps>`
  position: relative;
  z-index: 1;
  ${(props) =>
    props.open &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: ${props.theme.colors.selectBottomBorder};
      box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
      z-index: 4;
    `};
  &:focus {
    border-color: ${(props) => props.theme.colors.border};
    ${(props) =>
      props.open &&
      css`
        border-bottom-color: ${props.theme.colors.selectBottomBorder};
      `};
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.buttonPrimaryBgDisabled};
      border-color: ${props.theme.colors.buttonPrimaryTextDisabled};
      pointer-events: none;
    `};
`;

const StyledDropdown = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 0;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  max-height: 224px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
`;

type ItemProps = {
  selected?: boolean;
};

const Item = styled.a<ItemProps>`
  background-color: ${(props) =>
    props.selected ? props.theme.colors.selectItemHover : "#FFFFFF"};
  border-bottom: 1px solid ${(props) => props.theme.colors.selectItemDivider};
  color: ${(props) => props.theme.colors.inputText};
  cursor: pointer;
  display: block;
  ${(props) => props.theme.fonts.default}
  font-weight: ${(props) => (props.selected ? 400 : 300)};
  line-height: 20px;
  position: relative;
  padding: 17px 12px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.selectItemHover};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const LoadingDefault = () => <Item>Loading...</Item>;
