type Questions = {
  name: string;
  question: string;
  answers: { text: string; value: string }[];
};

export const questions: Questions[] = [
  {
    name: "q1",
    question:
      "If your friends only had one word to describe you, what would it be?",
    answers: [
      { text: "Loyal", value: "a" },
      { text: "Honest", value: "b" },
      { text: "Dependable", value: "c" },
      { text: "Unselfish", value: "d" },
    ],
  },
  {
    name: "q2",
    question:
      "What attribute kicks in to help you overcome trying circumstances?",
    answers: [
      { text: "Resilience", value: "a" },
      { text: "Courage", value: "b" },
      { text: "First to take action", value: "c" },
      { text: "Quick decision-making", value: "d" },
    ],
  },
  {
    name: "q3",
    question: "What makes you someone<br />others can count on?",
    answers: [
      { text: "I do what's right", value: "a" },
      { text: "I have good judgment", value: "b" },
      { text: "I make knowledgeable decisions", value: "c" },
      { text: "I take appropriate action", value: "d" },
    ],
  },
  {
    name: "q4",
    question: "What do you plan to accomplish in a year from now?",
    answers: [
      { text: "Graduate high school", value: "a" },
      { text: "Continue college", value: "b" },
      { text: "Learn new skills", value: "c" },
      { text: "Undecided", value: "d" },
    ],
  },
];
