import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";

export const Footer = ({ preCampaign, ...props }) => {
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const { maxPolicy, sweepsDisclaimer } = event.footer;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  return (
    <FooterWrapper as="footer" {...props}>
      <FooterContainer preCampaign={preCampaign}>
        {!preCampaign && (
          <>
            <SweepsDisclaimer
              onClick={() =>
                linkClickHandler(
                  "https://usmcmusic.com/shaylen/rules",
                  "Rules",
                  "footer"
                )
              }
            >
              {sweepsDisclaimer && ReactHtmlParser(sweepsDisclaimer)}
            </SweepsDisclaimer>
          </>
        )}

        {maxPolicy && (
          <div
          // onClick={() =>
          //   linkClickHandler(
          //     "https://www.max.live/",
          //     "MAX homepage/privacy policy",
          //     "footer",
          //   )
          // }
          >
            {ReactHtmlParser(maxPolicy)}
            {!!window.Cookiebot && (
              <CookieButton
                onClick={() => {
                  if (window.Cookiebot) {
                    window.Cookiebot.renew();
                  }
                }}
              >
                Click here to set Cookie Preferences.
              </CookieButton>
            )}
          </div>
        )}
      </FooterContainer>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 10px;
  z-index: 1;
  color: var(--color-text-default);
`;

const FooterContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  position: relative;
  background-color: #991b20;
  padding: 64px;
  font-family: var(--font-family-default);
  font-weight: 300;
  gap: 16px;

  & > div {
    width: 100%;
  }

  ${(p) => p.preCampaign && "text-align: center;"};

  @media (max-width: 950px) {
    font-size: 0.5rem;
    padding: 30px 32px;
  }
`;

const SweepsDisclaimer = styled.div`
  margin-top: 0;
`;

const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  color: #ffffff;
  display: inline;
  font-size: 10px;
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;
