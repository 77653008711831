const brand = {
  primary: "Expressway",
};

const emoji = "MaxEmoji";
const base = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, ${emoji}`;

export const fonts = {
  default: `${brand.primary}, ${base}`,
  header: `${brand.primary}, ${base}`,
};
