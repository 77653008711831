import { TimeProvider, EventProvider } from "context";
import { Routes } from "./Routes";
import "./theme/reset.css";
import "./App.css";

function App() {
  return (
    <TimeProvider>
      <EventProvider>
        <Routes />
      </EventProvider>
    </TimeProvider>
  );
}

export default App;
