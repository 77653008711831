import { Layout, MainContainer } from "Layout";
import { Divider, Title } from "./Register";
import { ResponsiveBody, ResponsiveH1, Button } from "Components";
import styled from "styled-components";
import { useAnalytics, useEvent, useUser } from "context";
import { ArtistContainerMobile } from "Layout";
import { useState } from "react";
import ShareCard from "Components/ShareCard";
import { definitions } from "./Register/definitions";

type UserSubmission = {
  ADDRESS1: string;
  ADDRESS2: string;
  CITY: string;
  DOB: string;
  EDU_LEVEL: string;
  EMAIL: string;
  FNAME: string;
  LNAME: string;
  PHONE: string;
  STATE: string;
  ZIP: string;
  q1: string;
  q2: string;
  q3: string;
  q4: string;
  short_code: string;
  submissionWindow: string;
  timezone: string;
};

const learnMoreUrl =
  "https://rmi.marines.com/request-information/WRRFY22MAXUSMCQUIZSITE";

export const Results = () => {
  const { assetUrl } = useEvent();
  const { userSubmissionDoc } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logClick } = useAnalytics();
  const userData = userSubmissionDoc?.data();

  const createShareLink = () => {
    if (userData) {
      let resultCode = `${userData.q1}-${userData.q2}-${userData.q3}`;
      resultCode = window.btoa(resultCode).replace(/=+$/, "");
      const shareLink = `https://usmcmusic.com/shaylen/share/${resultCode}`;
      console.log("shareLink", shareLink);
      return shareLink;
    }
  };

  return (
    <>
      <Layout>
        <Container>
          <Title>Your Marine Character Trait Results</Title>
          <ArtistContainerMobile
            style={{
              backgroundImage: `url(${assetUrl("hero_mobile_0116251207.jpg")})`,
            }}
          />
          <ResponsiveBody>
            You've registered for a chance to win signed merchandise from
            Shaylen.
            <br />
            <br />
            Your answers suggest you may possess the type of character we look
            for in our Marines. In fact, these are the same character attributes
            found in our 12 Character Traits.
          </ResponsiveBody>
          <Divider />
          <ResponsiveBody>
            <b>
              {
                definitions.q1[userData?.q1 as keyof typeof definitions.q1]
                  ?.label
              }
              :
            </b>{" "}
            {
              definitions.q1[userData?.q1 as keyof typeof definitions.q1]
                ?.definition
            }
            <br />
            <br />
            <b>
              {
                definitions.q2[userData?.q2 as keyof typeof definitions.q2]
                  ?.label
              }
              :
            </b>{" "}
            {
              definitions.q2[userData?.q2 as keyof typeof definitions.q2]
                ?.definition
            }
            <br />
            <br />
            <b>
              {
                definitions.q3[userData?.q3 as keyof typeof definitions.q3]
                  ?.label
              }
              :
            </b>{" "}
            {
              definitions.q3[userData?.q3 as keyof typeof definitions.q3]
                ?.definition
            }
          </ResponsiveBody>
          <Button
            onClick={() => {
              setIsModalOpen(true);
              logClick(
                { label: "Share Quiz button", url: "Share Quiz" },
                "internal"
              );
            }}
          >
            Share Your Results
          </Button>
        </Container>
        <LearnMoreCallout>
          <MainContainer>
            <ResponsiveH1>THINK YOU’RE MARINE MATERIAL?</ResponsiveH1>
            <ResponsiveBody>
              Ready to find out what it takes to become a Marine, and what it
              means for our Nation to be one? Talking to a Marine Recruiter can
              help determine not only if you're right for the Marine Corps, but
              if the Marine Corps is right for you.
            </ResponsiveBody>
            <Button
              variant="secondary"
              onClick={() => {
                window.open(learnMoreUrl, "_blank", "noopener");
                logClick(
                  { label: "Learn More", url: learnMoreUrl },
                  "external"
                );
              }}
            >
              Learn More
            </Button>
          </MainContainer>
        </LearnMoreCallout>
        {isModalOpen && (
          <ShareModalContainer>
            <ShareModalContent>
              <CloseButton onClick={() => setIsModalOpen(false)}>
                Close
              </CloseButton>
              <ShareCard
                url={createShareLink()}
                responses={userData as UserSubmission}
              />
            </ShareModalContent>
          </ShareModalContainer>
        )}
      </Layout>
    </>
  );
};

const CloseButton = styled.div`
  font-family: var(--font-family-default);
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #000;
  margin-bottom: 12px;
  align-self: flex-end;
  cursor: pointer;
`;

const ShareModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShareModalContent = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  padding: 10px;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;

  ${({ theme }) => theme.media.desktop} {
    padding: 12px 20px;
    width: 42%;
  }
`;

const LearnMoreCallout = styled.div`
  background-color: var(--color-secondary);
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  margin-top: ${(p) => p.theme.pxCalc("40px", "mobile")};
  ${MainContainer} {
    gap: ${(p) => p.theme.pxCalc("14px", "mobile")};
  }

  ${({ theme }) => theme.media.desktop} {
    margin-top: ${(p) => p.theme.pxCalc("72px")};
  }
`;

const Container = styled(MainContainer)`
  gap: 32px;
  flex: 0 0;
  b {
    font-weight: 700;
  }
  ${Divider} {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.mobile} {
    gap: ${(p) => p.theme.pxCalc("16px", "mobile")};

    ${Divider} {
      display: flex;
      margin-top: ${(p) => p.theme.pxCalc("8px", "mobile")};
      margin-bottom: ${(p) => p.theme.pxCalc("8px", "mobile")};
    }

    ${Button} {
      margin-top: ${(p) => p.theme.pxCalc("24px", "mobile")};
    }
  }
`;
