import React, { createContext, useContext, useMemo } from "react";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { firebase } from "context";

export const EventContext = createContext();
export const EventConsumer = EventContext.Consumer;

export const EventProvider = ({ children }) => {
  const [eventDocument, loading, error] = useDocumentData(
    firebase.firestore().doc(`events/${process.env.REACT_APP_EVENT_ID}`)
  );

  const event = useMemo(() => {
    return {
      ...eventDocument,
      id: process.env.REACT_APP_EVENT_ID,
      start: DateTime.fromJSDate(eventDocument?.start.toDate()),
      end: DateTime.fromJSDate(eventDocument?.end.toDate()),
      countdown: DateTime.fromJSDate(eventDocument?.startCountdown.toDate()),
      endSweeps: DateTime.fromJSDate(eventDocument?.endSweeps.toDate()),
      campaignStart: DateTime.fromJSDate(eventDocument?.campaignStart.toDate()),
      campaignEnd: DateTime.fromJSDate(eventDocument?.campaignEnd.toDate()),
      votingEnd: DateTime.fromJSDate(
        eventDocument?.transitionTimes.votingEnd.toDate()
      ),
    };
  }, [eventDocument]);

  if (loading) return null;

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = "789-marines-shaylen";

  const assetUrl = (asset) => `${api}/${storageDirectory}/${asset}`;

  return (
    <EventContext.Provider value={{ event, loading, error, assetUrl }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error("useEvent must be used within a Event Provider");
  }
  return context;
};
