import styled, { createGlobalStyle } from "styled-components";
import { Footer } from "./Footer";
import { useAnalytics, useEvent } from "context";
import brandLogo from "assets/marines-logo.png";

interface LayoutProps {
  children?: React.ReactNode;
  isPreCampaign?: boolean;
}
const GlobalStyle = createGlobalStyle`
  body {
    background-color: var(--color-primary);
  }
`;

export const Layout = ({ children, isPreCampaign }: LayoutProps) => {
  const { event, assetUrl } = useEvent();
  const { logClick } = useAnalytics();
  return (
    <>
      <GlobalStyle />
      {event.test && <TestEventBanner>Viewing Test Event</TestEventBanner>}
      <Wrapper>
        <ArtistImage src={assetUrl("hero_desktop_0116251207.jpg")} />
        <Logo
          src={brandLogo}
          onClick={() => {
            window.open("https://www.marines.com/", "_blank", "noopener");
            logClick(
              { label: "Marines Homepage", url: "https://www.marines.com/" },
              "header"
            );
          }}
        />
        <Main>{children}</Main>
        <Footer preCampaign={isPreCampaign} style={{ gridArea: "footer" }} />
      </Wrapper>
    </>
  );
};

const Logo = styled.img`
  width: 175px;
  height: 44px;
  grid-area: logo;
  justify-self: center;
  margin-top: ${(p) => p.theme.pxCalc("8px", "mobile")};
  ${({ theme }) => theme.media.desktop} {
    width: 240px;
    height: 60px;
    margin-top: ${(p) => p.theme.pxCalc("54px")};
  }
`;

const Main = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

const ArtistImage = styled.img`
  width: 100%;
  grid-area: artist;
  aspect-ratio: 0.679;
  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 54.6% 1fr;
  grid-template-areas:
    "artist logo"
    "artist main"
    "footer footer";
  background-color: var(--color-primary);
  color: #ffffff;
  width: 100%;
  position: relative;

  ${({ theme }) => theme.media.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "logo"
      "main"
      "footer";
    justify-items: center;
  }
`;

const TestEventBanner = styled.div`
  background-color: #ff003b;
  color: #ffffff;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 12px;
  font-family: var(--font-family-default);
  text-align: center;
  padding: 3px 10px;

  @media (max-width: 950px) {
    display: none;
  }
`;
