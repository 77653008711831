export const definitions = {
  q1: {
    a: {
      label: "Loyalty",
      definition:
        "The loyalty to those on our left and right is expressed in our motto, Semper Fidelis, from the fellow Marines we fight alongside to the communities we fight for.",
    },
    d: {
      label: "Unselfishness",
      definition:
        "There are few endeavors as selfless as becoming a Marine. This team-first mentality becomes part of every Marine’s DNA, from the battlefields we fight on to the communities we serve in.",
    },
    b: {
      label: "Integrity",
      definition:
        "We earn the trust of our fellow Marines we fight alongside, the trust our Nation places in us to win its battles and the trust of every American to at all times serve with honor. It is a trust we will continue to earn.",
    },
    c: {
      label: "Dependability",
      definition:
        "Marines find a way to win, adapting to find solutions—not excuses. We are counted among those our Nation can always count on.",
    },
  },
  q2: {
    a: {
      label: "Endurance",
      definition:
        "It’s impossible to lead from the front if you’re falling behind. Marines continue on when others quit, digging deep to find a second wind when there’s seemingly nothing left to give.",
    },
    b: {
      label: "Courage",
      definition:
        "Marines learn many forms of courage, from the physical courage to face the unknown to the moral courage to do the right thing even when no one is looking.",
    },
    c: {
      label: "Initiative",
      definition:
        "When there’s a battle to be won, no Marine waits to be told what to do. While fighting on every front or contributing to the home front, Marines find ways to win.",
    },
    d: {
      label: "Decisiveness",
      definition:
        "Marines use the information at hand to respond quickly and decisively, because when the stakes are highest, the worst decision is not making one.",
    },
  },
  q3: {
    a: {
      label: "Justice",
      definition:
        "It is critical for unit cohesion that rewards, punishments, opportunities and assignments are given impartially, consistently and justly. This commitment to our system of justice enables Marines to prevent and resolve disputes encountered at home and abroad.",
    },
    b: {
      label: "Judgment",
      definition:
        "Even the most sophisticated plan will fail if it’s based on bad judgment. Marines continually develop and hone their judgment to ensure the right decisions and actions are made on behalf of our Nation.",
    },
    c: {
      label: "Knowledge",
      definition:
        "Without knowledge, judgment is reduced to intuition and decision-making becomes nothing more than a guess. Marines consistently and constantly seek knowledge and self-improvement to ensure they’re at all times ready to win for our Nation.",
    },
    d: {
      label: "Tact",
      definition:
        "Whether issuing orders to fellow Marines or advising locals in a foreign country, Marines know that how you say something can be just as important as what you say.",
    },
  },
};
