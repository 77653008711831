import { SvgCheck, SvgError } from "assets";
import styled, { css } from "styled-components";

export type InputProps = {
  onChange: (value: string) => void;
  value?: string;
  error?: boolean;
  disabled?: boolean;
  placeholderStyle?: string;
  name?: string;
  className?: string;
  success?: boolean;
} & Omit<React.HTMLAttributes<HTMLInputElement>, "onChange" | "value">;

export const Input = ({
  onChange,
  value = "",
  placeholder,
  error,
  success,
  disabled,
  placeholderStyle,
  className,
  ...props
}: InputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <TextContainer error={!!error} disabled={!!disabled} className={className}>
      <InputFieldContainer
        onChange={handleChange}
        value={value}
        error={error}
        disabled={disabled}
        {...props}
      />
      <InputPlaceholder error={error} isOffset={!!value} htmlFor={props.name}>
        {placeholder}
      </InputPlaceholder>
    </TextContainer>
  );
};

type TextContainerProps = {
  error: boolean;
  disabled: boolean;
};

const TextContainer = styled.div<TextContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: var(--border-radius);
  position: relative;
  width: 100%;
`;

const offsetPlaceholderStyle = css`
  top: -18px;
  left: 0;
  padding: 0;
  font-size: 11px;
  font-weight: 300;
  color: var(--color-text-default);
`;

type FieldOptionProps = {
  error?: boolean;
  success?: boolean;
  isOffset?: boolean;
};

const FieldStatusIndicator = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  position: absolute;
  pointer-events: none;
  top: -18px;
  right: 12px;
`;

export const InputPlaceholder = styled.label<FieldOptionProps>`
  display: flex;
  flex-direction: row;
  color: var(--color-text-input);
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--font-weight-default);
  position: absolute;
  pointer-events: none;
  padding-left: 16px;

  ${(p) =>
    (p.error || p.success) &&
    css`
      color: var(--color-text-error);
    `};

  ${(p) => p.success && css``};

  ${(props) => props.isOffset && offsetPlaceholderStyle};
`;

type FieldProps = {
  error?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const InputFieldContainer = styled.input<FieldProps>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  height: 44px;

  appearance: none;
  background-color: transparent;
  border: 1px solid #000000;
  border-radius: var(--border-radius);
  outline: 0;
  z-index: 1;

  font-family: var(--font-family-default);
  font-weight: var(--font-weight-default);
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-input);

  &:focus {
    border: 1px solid #4580dd;
  }

  &:focus + label {
    ${offsetPlaceholderStyle}
  }

  &:disabled {
    border: 1px solid #626466;
  }

  ${(props) =>
    props.error &&
    css`
      border: 2px solid var(--color-border-error);

      &:focus {
        border: 2px solid var(--color-border-error);
      }
    `};
`;
