import question_1_a from "./question_1_a.jpg";
import question_1_b from "./question_1_b.jpg";
import question_1_c from "./question_1_c.jpg";
import question_1_d from "./question_1_d.jpg";
import question_2_a from "./question_2_a.jpg";
import question_2_b from "./question_2_b.jpg";
import question_2_c from "./question_2_c.jpg";
import question_2_d from "./question_2_d.jpg";
import question_3_a from "./question_3_a.jpg";
import question_3_b from "./question_3_b.jpg";
import question_3_c from "./question_3_c.jpg";
import question_3_d from "./question_3_d.jpg";
import question_4_a from "./question_4_a.jpg";
import question_4_b from "./question_4_b.jpg";
import question_4_c from "./question_4_c.jpg";
import question_4_d from "./question_4_d.jpg";

export const questionImages = {
  question_1_a,
  question_1_b,
  question_1_c,
  question_1_d,
  question_2_a,
  question_2_b,
  question_2_c,
  question_2_d,
  question_3_a,
  question_3_b,
  question_3_c,
  question_3_d,
  question_4_a,
  question_4_b,
  question_4_c,
  question_4_d,
};
